import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "text", "button", "icon" ]
  static classes = [ "text", "icon" ]
  static values = {
    open: { type: Boolean, default: false },
    label: String,
    labelOpen: String
  }

  toggle() {
    this.openValue = !this.openValue
  }
  
  openValueChanged(isOpen) {
    this.textTarget.classList.toggle(this.textClass, !isOpen)
    
    if (this.hasButtonTarget) {
      this.buttonTarget.innerHTML = isOpen ? this.labelOpenValue : this.labelValue
      isOpen && this.buttonTarget.scrollIntoView({ behavior: "smooth" })
    }
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle(this.iconClass, isOpen)
      isOpen && this.iconTarget.scrollIntoView({ behavior: "smooth" })
    }
    isOpen && this.dispatch("opened")
  }
}
