import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    yes: { type: Boolean, default: false },
    opened: { type: Boolean, default: false },
    count: Number
  }
  static targets = [ "none", "select", "list", "icon", "checkbox", "counter", "firstRow" ]

  yesValueChanged(yes) {
    this.openedValue = yes
    if (!yes) {
      this.countValue = 0
      this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    }
  }
  openedValueChanged(opened) {
    const heightOpened = `${this.checkboxTargets.length * 2.75 + 1}rem`
    this.iconTarget.classList.toggle('rotate-180', opened)
    this.listTarget.style.height = opened ? heightOpened : '0rem'
    this.firstRowTarget.classList.toggle('border-b-0', opened)
    this.firstRowTarget.classList.toggle('rounded-b-none', opened)
  }

  countValueChanged(count) {
    switch(count) {
      case 0:
        this.counterTarget.innerHTML = `Medikamente auswählen`
        break
      case 1:
        this.counterTarget.innerHTML = `1 Medikament`
        break
      default:
        this.counterTarget.innerHTML = `${count} Medikamente`
    }
  }

  no() {
    this.yesValue = false
  }

  yes() {
    this.yesValue = true
  }

  selected() {
    this.countValue = this.checkboxTargets.filter(checkbox => checkbox.checked).length
  }

  toggleList() {
    if (!this.openedValue) {
      this.selectTarget.checked = true
    }
    this.openedValue = !this.openedValue
  }

}
