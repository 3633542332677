import { Controller } from "@hotwired/stimulus"

// Meant to intercept the click on linkOrButton.
// Instead the configured dialog is displayed on which the user
// can either confirm or cancel the initial action.
export default class extends Controller {
  static targets = [ "container", "body", "confirmButtonLabel", "cancelButton", "cancelButtonLabel" ]

  connect() {
    this.currentLinkOrButton = null;
    document.body.addEventListener('dialog:open', this.handleCustomOpen.bind(this));
  }

  handleCustomOpen({ detail: { originalEvent, body, confirmButtonLabel, cancelButtonLabel }}) {
    this.updateLabels(body, confirmButtonLabel, cancelButtonLabel);
    this.currentLinkOrButton = originalEvent.target;
    this._handleOriginalEvent(originalEvent);
  }

  open(event) {
    const params = event.params;
    this.updateLabels(params.body, params.confirmButtonLabel, params.cancelButtonLabel);
    this.currentLinkOrButton = event.target;
    this._handleOriginalEvent(event);
  }

  updateLabels(body, confirmButtonLabel, cancelButtonLabel) {
    this.bodyTarget.innerHTML = body;
    this.confirmButtonLabelTarget.innerHTML = confirmButtonLabel;

    if(cancelButtonLabel) {
      this.cancelButtonTarget.classList.remove('hidden');
      this.cancelButtonLabelTarget.innerHTML = cancelButtonLabel;
    } else {
      this.cancelButtonTarget.classList.add('hidden');
    }
  }

  _handleOriginalEvent(event) {
    if('true' !== this.currentLinkOrButton.dataset.dialogConfirmed) {
      event.preventDefault()
      event.stopImmediatePropagation();
      this.containerTarget.classList.remove('hidden');
    }
  }

  confirm() {
    this.currentLinkOrButton.dataset.dialogConfirmed = true;
    this.currentLinkOrButton.click();
    this.currentLinkOrButton.dataset.dialogConfirmed = null;
    this.hide();
  }

  hide() {
    this.containerTarget.classList.add('hidden');
  }
}

export function open(originalEvent, body, confirmButtonLabel, cancelButtonLabel = false) {
  document.body.dispatchEvent(new CustomEvent('dialog:open', { detail: { originalEvent, body, confirmButtonLabel, cancelButtonLabel } }));
}
