import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress-line"
export default class extends Controller {

  static targets = [ "icon", "line" ]

  connect() {
    this.drawLines()
  }

  drawLines() {
    const rects = this.iconTargets.map(icon => icon.getBoundingClientRect())
    this.lineTargets.forEach((line, index) => {
      const dist = rects[index + 1].top - rects[index].top - rects[index + 1].height
      line.style.top = `${rects[index].height}px`
      line.style.height = `${dist}px`
    })
  }
}
