import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeoutInMilliseconds: { type: Number, default: 5000 },
  }

  connect() {
    const dayOfYear = Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))

    if (parseInt(localStorage.getItem('splashscreen-seen-doy')) !== dayOfYear) {
      this.element.style.display = ''
      window.setTimeout(() => { this.element.style.display = "none" }, this.timeoutInMillisecondsValue)
      localStorage.setItem('splashscreen-seen-doy', dayOfYear)
    } else {
      this.element.style.display = 'none'
    }
  }
}
