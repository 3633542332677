import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "textField" ]

  connect() {
    setTimeout(() => this.update(), 10)
  }

  update() {
    if(this.textFieldTarget.value.length >= 1) {
      this.dispatch("nameEntered")
    } else {
      this.dispatch("nameMissing")
    }
  }
}
