import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rating"
export default class extends Controller {
  
  static classes = [ "open", "filled" ]
  static targets = [ "star", "positiveFeedback", "negativeFeedback", "starsInput" ]
  static values = { stars: Number }
  
  rate(event) {
    this.starsValue = event.params.stars
  }
  
  starsValueChanged(stars) {
    if (stars === 0) return
    
    const positive = stars > 2
    
    this.starsInputTarget.value = stars
    
    this.positiveFeedbackTarget.classList.toggle("hidden", !positive)
    this.negativeFeedbackTarget.classList.toggle("hidden", positive)
    
    this.starTargets.forEach(star => {
      const filled = parseInt(star.dataset.ratingStarsParam) <= stars
      star.classList.toggle(this.openClass, !filled)
      star.classList.toggle(this.filledClass, filled)
    })
  }
}
