import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "helpfulNote", "notHelpfulNote" ]

  select({ params: { helpful }}) {
    this.helpfulNoteTarget.classList.toggle("hidden", !helpful);
    this.notHelpfulNoteTarget.classList.toggle("hidden", helpful);
  }

}
