import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    disabled: { type:Boolean, default: false },
  }
  static classes = [ "disabled", "notDisabled" ]

  disabledValueChanged() {
    this.disabledClasses.forEach((klass) => this.element.classList.toggle(klass, this.disabledValue))
    this.notDisabledClasses.forEach((klass) => this.element.classList.toggle(klass, !this.disabledValue))
    this.element.disabled = this.disabledValue
  }

}
