import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "dialog", "overlay" ]
  static classes = [ "modalVisible", "overlayVisible", "overlayVisibleDelayed" ]

  connect() {
    // NB: Enabling the transition with a delay so the initial
    // hide-operation of the navigation is not visible to the user.
    this.hasDialogTarget && setTimeout(() => {
      this.dialogTarget.classList.add("transition", "duration-200")
    }, 1)
  }

  show(event) {
    event.stopPropagation();
    this.dialogTarget.classList.add(...this.modalVisibleClasses)
    this.overlayTarget.classList.remove(this.overlayVisibleDelayedClass)
    this.overlayTarget.classList.add(this.overlayVisibleClass)
  }

  hide(event) {
    event.stopPropagation();
    this.dialogTarget.classList.remove(...this.modalVisibleClasses)
    setTimeout(() => {
      this.overlayTarget.classList.add(this.overlayVisibleDelayedClass) },
      200) // NB: the timeout value should be in sync with the duration of the transition
    this.overlayTarget.classList.remove(this.overlayVisibleClass)
  }
}
