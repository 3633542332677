import { Controller } from "@hotwired/stimulus";
import DraggablePiechart from "@/javascript/draggable-piechart.js"

export default class extends Controller {

  static targets = [ "chart", "hint", "answer" ]
  static values = { items: Array }

  connect() {
    const pieChart = new DraggablePiechart({
      canvas: this.chartTarget,
      proportions: this.itemsValue,
      onchange: (object) => {
        const percentages = object.getAllSliceSizePercentages()
        const roundedPercentages = percentages.map(value =>  Math.round(value))
        this.hasAnswerTarget && (this.answerTarget.value = JSON.stringify(roundedPercentages))
      },
    })

    this.hintTarget.addEventListener('click', () => {
      this.hintTarget.classList.add("hidden")
      localStorage.setItem("piechart-utilization-hint-seen", true)
    })

    localStorage.getItem("piechart-utilization-hint-seen") && this.hintTarget.classList.add("hidden")
  }
}
