import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "button", "activator" ]

  activate(event) {
    event.currentTarget.dataset.valid = event.currentTarget[event.params.condition]

    const shouldBeActive = this.activatorTargets.every(element => element.dataset.valid === "true")
    if(shouldBeActive) {
      this.activateButton();
    } else {
      this.deactivateButton();
    }
  }

  deactivateButton() {
    this.buttonTarget.dataset.buttonComponentDisabledValue = true;
  }

  activateButton() {
    this.buttonTarget.dataset.buttonComponentDisabledValue = false;

    if(undefined !== this.buttonTarget.dataset.href) {
      this.buttonTarget.href = this.buttonTarget.dataset.href
    }
  }
}
