import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  
  static values = {
    shown: { type: Boolean, default: false }
  }
  static targets = [ "spinner" ]
  
  shownValueChanged() {
    this.spinnerTarget.classList.toggle("hidden", !this.shownValue)
  }
  
  show() {
    this.shownValue = true
    // console.log('show spinner')
  }
  hide() {
    this.shownValue = false
    // console.log('hide spinner')
  }
}
