import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["tooltip", "tooltipText"]
  static classes = ["visibility", "hidden"]
  static values = { text: String, successMessage: String, errorMessage: String }

  connect() {
    this.tooltipTarget.classList.add(...this.hiddenClasses)
  }

  copy() {
    navigator.clipboard
      .writeText(this.textValue)
      .then(() => {
        this.tooltipTextTarget.innerHTML = this.successMessageValue
      })
      .catch(() => {
        this.tooltipTextTarget.innerHTML = this.errorMessageValue
      })
      .finally(() => {
        // show tooltip
        this.tooltipTarget.classList.remove(...this.hiddenClasses)
        this.tooltipTarget.classList.add(...this.visibilityClasses)

        // hide tooltip after 3 seconds
        window.setTimeout(() => {
          this.tooltipTarget.classList.remove(...this.visibilityClasses)
          this.tooltipTarget.classList.add(...this.hiddenClasses)
        }, 3000)
      })
  }
}
