import { Controller } from "@hotwired/stimulus"
import date from "date-and-time";
import { patch } from "@rails/request.js"

export default class extends Controller {

  static targets = [ "media", "playButton", "currentTime", "timeline" ]
  static classes = [ "pause", "play" ]
  static values = {
    startAt:       { type: Number, default: 0 },
    updateTimeUrl: String,
    mainlyWatched: { type: Boolean, default: false },
  }

  // callbacks

  mediaTargetConnected(element) {
    this.media = this.mediaTarget
    this.media.onplay = () => this.onPlay()
    this.media.onpause = () => this.onPause()
    this.media.ontimeupdate = () => this.onTimeUpdate()
    this.media.onended = () => this.onEnded()
    this.timelineTarget.oninput = () => this.onChangeSeek()
    this.media.addEventListener('canplay', () => this.onCanPlay(this.media))
    
    // console.log(this.media.networkState)
    // this.media.oncanplay = () => this.dispatch("can-play")
    // this.media.onloadeddata = () => console.log('loadeddata')
    // this.media.onloadedmetadata = () => console.log('loadedmetadata')
    // this.media.onloadstart = () => console.log('loadstart')
    // this.media.onstalled = () => console.log('stalled')
    this.media.onwaiting = () => {
      this.dispatch("waiting")
      // console.log('waiting')
    }
  }

  mediaTargetDisconnected(element) {
    // to not trigger the saveCurrentTime again
    this.media.onpause = null
  }

  // actions

  togglePlay() {
    this.media.paused ? this.play() : this.pause()
  }

  play() {
    this.media.play()
  }

  pause() {
    this.media.pause()
  }

  rewind() {
    // todo: save "rewinded" event into analytics/tracking, including current position
    this.media.currentTime = this.media.currentTime - 10
    this.saveCurrentTime()
  }

  fastForward() {
    // todo: save "forwarded" event into analytics/tracking, including current position
    this.media.currentTime = this.media.currentTime + 10
    this.saveCurrentTime()
  }

  // private

  onCanPlay(mediaElement) {
    if(mediaElement.dataset.startAtHasBeenSet !== 'true') {
      this.media.currentTime = this.startAtValue
      mediaElement.dataset.startAtHasBeenSet = true
    }
    this.dispatch("can-play")
    // console.log('canplay')
  }

  onPlay() {
    this.displayPlayButton(false)
  }

  onPause() {
    this.displayPlayButton(true)
    this.saveCurrentTime()
  }

  onTimeUpdate () {
    const percentagePosition = isNaN(this.media.duration) ? 0 : ((100 * this.media.currentTime) / this.media.duration)
    if (percentagePosition > 90) this.mainlyWatchedValue = true
    this.timelineTarget.style.backgroundSize = `${percentagePosition}% 100%`
    this.timelineTarget.value = percentagePosition
    this.currentTimeTarget.textContent = this.formatTime(this.media.currentTime)
  }

  onEnded () {
    this.element.dataset.testPlaybackEnded = true
  }

  onChangeSeek () {
    const time = (this.timelineTarget.value * this.media.duration) / 100
    this.media.currentTime = time
    this.saveCurrentTime()
  }

  displayPlayButton(display) {
    this.playButtonTarget.classList.toggle(this.playClass, display)
    this.playButtonTarget.classList.toggle(this.pauseClass, !display)
  }

  formatTime(seconds) {
    const time = new Date(seconds * 1000)
    return date.format(time, 'mm:ss')
  }

  saveCurrentTime() {
    this.hasUpdateTimeUrlValue &&
      patch(this.updateTimeUrlValue, { body: { time: this.media.currentTime} })
  }

  mainlyWatchedValueChanged() {
    this.mainlyWatchedValue && this.dispatch("mainly-watched")
  }
}
