import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static classes = [ "scaleActive", "scaleInactive" ]

  static targets = [
    "wellBeingInput", "wellBeingIcon",
    "stressLevelInput", "stressLevelBar",
    "migraneImpedimentLevelInput", "migraneImpedimentLevelBar"
  ]

  connect() {
    this.updateWellBeingIconStates()
    this.updateStressLevelScale()
    this.updateMigraneImpedimentLevelScale()
  }

  setWellBeing({ params: { value }}) {
    this.wellBeingInputTarget.value = value
    this.updateWellBeingIconStates(value)
    this.updateCompleteState()
  }

  setStressLevel({ params: { value }}) {
    this.stressLevelInputTarget.value = value
    this.updateStressLevelScale(value)
    this.updateCompleteState()
  }

  setMigraneImpedimentLevel({ params: { value }}) {
    this.migraneImpedimentLevelInputTarget.value = value
    this.updateMigraneImpedimentLevelScale(value)
    this.updateCompleteState()
  }

  updateCompleteState() {
    const valueSet = (input) => input.value !== ""
    const complete = [
      this.wellBeingInputTarget,
      this.stressLevelInputTarget,
      this.migraneImpedimentLevelInputTarget
    ].every(valueSet)

    if(complete) {
      this.dispatch("complete")
    }
  }

  updateWellBeingIconStates(value) {
    const updateIcon = (icon) => {
      const active = Number.parseInt(icon.dataset.value) === value
      const moodKey = icon.dataset.moodKey;
      let newIconClassName;

      if (active) {
        newIconClassName = `icon-mood-${moodKey}-filled text-blue-600`;
      } else {
        newIconClassName = `icon-mood-${moodKey}`
      }

      icon.className = newIconClassName
    }
    this.wellBeingIconTargets.forEach(updateIcon)
  }

  updateStressLevelScale(value) {
    this.stressLevelBarTargets.forEach(element => {
      this.updateBar(value, element)
    });
  }

  updateMigraneImpedimentLevelScale(value) {
    this.migraneImpedimentLevelBarTargets.forEach(element => {
      this.updateBar(value, element)
    });
  }

  updateBar(value, element) {
    const parsedValue = Number.parseInt(element.dataset.value)
    const active = parsedValue <= value
    element.classList.toggle(this.scaleActiveClass, active)
    element.classList.toggle(this.scaleInactiveClass, !active)
  }

}
