import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "progress", "progressText", "done", "finished" ]
  static values = {
    timeoutInMiliseconds: Number
  }

  connect() {
    setTimeout(
      (() => {
        this.progressTextTarget.classList.add("hidden")
        this.doneTarget.classList.remove("hidden")
      }),
      this.timeoutInMilisecondsValue
    )
  }

  showFinished(event) {
    event.preventDefault()
    this.progressTarget.classList.add("hidden")
    this.doneTarget.classList.add("hidden")
    this.finishedTarget.classList.remove("hidden")
  }
}
