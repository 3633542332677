import { Controller } from "@hotwired/stimulus"
import { open as openDialog } from './dialog_controller';

export default class extends Controller {
  static targets = [ "checkbox" ]
  static values = {
    consentWasGiven: { type: Boolean, default: true },
  }

  submit(event) {
    if(this.consentWasGivenValue && !this.checkboxTarget.checked) {
      openDialog(
        event,
        `Bist du sicher, dass du deine Zustimmung zur Verarbeitung deiner persönlichen und gesundheitlichen Daten widerrufen möchtest?
        Wenn du der Verarbeitung nicht zustimmst, kannst du keine der Dienste mehr nutzen – auch nicht den Therapiekurs.`,
        `Ja, Widerruf bestätigen`,
        `Nein, abbrechen`
      );
    }
  }
}
