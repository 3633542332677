import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

// Connects to data-controller="lottie-player"
export default class extends Controller {

  static values = {
    animation: Object
  }

  connect() {
    lottie.loadAnimation({
      container: this.element,
      autoplay: true,
      loop: false,
      renderer: 'html',
      animationData: this.animationValue
    })
    .setSubframe(false)
  }

}
