import { Controller } from "@hotwired/stimulus"
import Swiper, { Pagination } from "swiper"

export default class extends Controller {

  static targets = [ "pagination" ]

  connect() {
    const swiper = new Swiper(this.element, {
      modules: [ Pagination],
      pagination: {
        el: this.paginationTarget,
        type: 'bullets',
        clickable: true
      },
    })
  }
}
