import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  static values = {
    progress: Number
  }
  
  static targets = [ "bar" ]
  
  connect() {
    setTimeout((() => this.barTarget.style.width = `${this.progressValue}%`), 200);
    // this.barTarget.style.width = `${this.progressValue}%`
  }
}
