import { Controller } from "@hotwired/stimulus"

  // Connects to data-controller="none-selector"
export default class extends Controller {
  
  static targets = [ "checkbox", "none" ]
  
  none() {
    if(this.noneTarget?.checked) this.checkboxTargets.forEach(cb => cb.checked = false)
  }
  
  select() {
    if(this.checkboxTargets.filter(cb => cb.checked).length) this.noneTarget.checked = false
  }
}
