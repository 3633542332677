import { Controller } from "@hotwired/stimulus"
import { open as openDialog } from './dialog_controller';

export default class extends Controller {
  connect() {
    this.hostname = this.getHostname(window.location.href);
  }

  handleClick(event) {
    const { target } = event;
    if(target.tagName !== "A") return;
    if(this.hostname === this.getHostname(target.href)) return;

    openDialog(
      event,
      `Mit diesem Link verlässt du den headacy-Therapiekurs.`,
      `Fortfahren`,
      `Abbrechen`
    );
  }

  getHostname(url) {
    return (new URL(url)).host;
  }
}
