import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "videoScreen", "poster" ]
  static values = {
    landscapePoster: String,
    portraitPoster: String,
  }

  // callbacks

  connect() {
    // const addAspectRatio = () => {
    //   this.posterTarget.style.height = `${this.posterTarget.offsetWidth / 16 * 9}px`
    // }

    if (window.innerWidth > window.innerHeight) {
      this.posterTarget.style.backgroundImage = `url('${this.landscapePosterValue}')`
      //addAspectRatio()
      //window.addEventListener('resize', () => { addAspectRatio() })
    } else {
      this.posterTarget.style.backgroundImage = `url('${this.portraitPosterValue}')`
    }
  }

  // actions

  openVideoScreen() {
    this.videoScreenTarget.classList.remove("hidden")
    document.documentElement.style.overflow = "hidden"
  }

  closeVideoScreen() {
    this.videoScreenTarget.classList.add("hidden")
    document.documentElement.style.overflow = "visible"
  }
}
