
// Example: Load Rails libraries in Vite.
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', { eager: true })

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.glob('~/controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)


// Example: Import a stylesheet in app/frontend/index.css
import '~/stylesheets/application.sass'
