import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "body", "registrationMain", "therapyProgress", "pageComponentMain", "overlay" ]

  static transitions = {
    none: [],
    fullFade: [
      {
        targetName: "body",
        exitAnimation: "page-transition-fadeOut",
        entranceAnimation: "page-transition-fadeIn",
      },
    ],
    pageFade: [
      {
        targetName: "pageComponentMain",
        exitAnimation: "page-transition-fadeOut",
        entranceAnimation: "page-transition-fadeIn",
      },
    ],
    registrationPageFade: [
      {
        targetName: "registrationMain",
        exitAnimation: "page-transition-fadeOut",
        entranceAnimation: "page-transition-fadeIn",
      },
    ],
    therapyWeekSwitch: [
      {
        targetName: "therapyProgress",
        exitAnimation: "page-transition-fadeOut",
        entranceAnimation: "page-transition-fadeIn",
      },
    ],
  }

  connect() {
    this.currentTransition = undefined

    window.addEventListener("turbo:visit", this.handleVisit.bind(this))
    window.addEventListener("turbo:before-render", this.handleBeforeRender.bind(this))
    window.addEventListener("page-transitions:exit-animation-completed", this.handleExitAnimationCompleted.bind(this))
    window.addEventListener("page-transitions:entrance-animation-completed", this.handleEntranceAnimationCompleted.bind(this))
  }

  disconnect() {
    window.removeEventListener("turbo:visit", this.handleVisit.bind(this))
    window.removeEventListener("turbo:before-render", this.handleBeforeRender.bind(this))
    window.removeEventListener("page-transitions:exit-animation-completed", this.handleExitAnimationCompleted.bind(this))
    window.removeEventListener("page-transitions:entrance-animation-completed", this.handleEntranceAnimationCompleted.bind(this))
  }

  setTransition ({ params: { transition } }) {
    this.currentTransition = transition
  }

  bodyTargetConnected(element) {
    this.handleEntranceAnimation(element, 'body')
  }

  registrationMainTargetConnected(element) {
    this.handleEntranceAnimation(element, 'registrationMain')
  }

  therapyProgressTargetConnected(element) {
    this.handleEntranceAnimation(element, 'therapyProgress')
  }

  pageComponentMainTargetConnected(element) {
    this.handleEntranceAnimation(element, 'pageComponentMain')
  }

  handleEntranceAnimation(element, targetName) {
    // NB: Find the corresponding config for the given element.
    const config = Array.from(this.transitions[this.activeTransition]).find(config => config.targetName === targetName)

    if (!config || !config.entranceAnimation) {
      return
    }

    this.animateEntrance(element, config.entranceAnimation)
  }

  handleVisit (event) {
    // NB: When Turbo handles a http redirect response it will dispatches a `turbo:visit`-event
    // with the event.detail.action set to 'replace' – so the location.history is updated properly.
    // In that case we want to suppress any exit animation.
    if (event.detail.action === 'replace') {
      return false
    }

    if (undefined === this.transitions[this.activeTransition]) {
      console.error(`Called undefined transition: ${this.activeTransition}.\n\nValid transitions are: ${Object.keys(this.transitions).join(', ')}.`)
      return false
    }

    Array.from(this.transitions[this.activeTransition]).forEach(config => {
      const element = this[`${config.targetName}Target`]
      if (!config.exitAnimation) return

      if (config.entranceAnimation) {
        this.showOverlay()
      }
      this.animateExit(element, config.exitAnimation)
    })
  }

  animateExit(element, exitAnimation) {
    this.animateElement(element, 'exit', exitAnimation)
  }

  animateEntrance(element, entranceAnimation) {
    this.animateElement(element, 'entrance', entranceAnimation)
  }

  animateElement(element, type, animation) {
    element.setAttribute(`data-${type}-animation-active`, true)
    element.classList.add('page-transition-base', animation)

    element.addEventListener('animationend', (event) => {
      // NB: Otherwise this event may bubble up to a parent element which got
      // its separate eventListener
      event.stopPropagation()

      element.removeAttribute(`data-${type}-animation-active`)
      element.classList.toggle('invisible', type === 'exit')
      this.removeAnimationClasses(element)
      this.notifyAnimationCompleted(type)
    }, { once: true })
  }

  removeAnimationClasses(element) {
    Array.from(element.classList).filter(className => className.startsWith('page-transition-')).forEach(className => element.classList.remove(className))
  }

  notifyAnimationCompleted(type) {
    document.body.dispatchEvent(new CustomEvent(`page-transitions:${type}-animation-completed`, { bubbles: true }))
  }

  async handleBeforeRender (event) {
    event.preventDefault()

    await this.allExitAnimationsCompleted()

    if(this.clearEntranceAnimationIntervalId) {
      clearInterval(this.clearEntranceAnimationIntervalId)
    }
    this.clearEntranceAnimationIntervalId = setInterval(this.handleEntranceAnimationCompleted.bind(this), 50)

    event.detail.resume()
  }

  handleExitAnimationCompleted() {
    if (this.allExitAnimationsCompletedResolve && this.noElementsActivelyAnimated('exit')) {
      this.allExitAnimationsCompletedResolve()
    }
  }

  handleEntranceAnimationCompleted() {
    if (this.noElementsActivelyAnimated('entrance')) {
      this.hideOverlay()
      clearInterval(this.clearEntranceAnimationIntervalId)
      this.clearEntranceAnimationIntervalId = null
      this.currentTransition = undefined
    }
  }

  get activeTransition() {
    return (this.currentTransition || 'fullFade')
  }

  get transitions() {
    return this.constructor.transitions
  }

  async allExitAnimationsCompleted() {
    return new Promise(resolve => {
      if (this.noElementsActivelyAnimated('exit')) {
        return resolve(true)
      }

      this.allExitAnimationsCompletedResolve = () => {
        return resolve(true)
      }
    })
  }

  showOverlay() {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.remove('hidden')
    }
  }

  hideOverlay() {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.add('hidden')
    }
  }

  noElementsActivelyAnimated(type) {
    return document.querySelectorAll(`[data-${type}-animation-active]`).length === 0
  }
}
