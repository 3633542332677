import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "icon", "input" ]
  static classes = [ "show", "hide" ]

  toggle() {
    if (this.inputTarget.type === "password") {
      this.inputTarget.type = "text"
      this.iconTarget.classList.remove(this.showClass)
      this.iconTarget.classList.add(this.hideClass)
    }
    else {
      this.inputTarget.type = "password"
      this.iconTarget.classList.add(this.showClass)
      this.iconTarget.classList.remove(this.hideClass)
    }
  }
}
