import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
    this.select()
  }

  select() {
    const checkboxes = Array.from(this.checkboxTargets)
    if (checkboxes.filter(element => element.checked).length >= 2) {
      this.dispatch("enoughAnswersGiven")
    } else {
      this.dispatch("notEnoughAnswersYet")
    }
  }
}
