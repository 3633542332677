import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "input" ]

  static values = {
    code: String
  }

  connect() {
    this.form = this.element;

    if (this.codeValue.length > 0) {
      this.codeValue.split('').forEach((char, index) => {
        this.inputTargets[index].value = char;
      });
    } else {
      this.selectInput(this.inputTargets[0]);
    }

    this.inputTargets.forEach((input, index) => {
      input.addEventListener('focus', (event) => {
        this.selectInput(event.target);
      });

      input.addEventListener('keydown', (event) => {
        const currentInput = event.target;
        const previousInput = this.inputTargets[Math.max(0, index - 1)];

        // backspace and the current field is empty
        if (event.keyCode === 8 && currentInput.value.length === 0) {
          this.selectInput(previousInput);
        }

        // left arrow key
        if (event.keyCode == 37) {
          this.selectInput(previousInput);
          event.preventDefault();
        }

        // right arrow key
        if (event.keyCode === 39) {
          const nextInput = this.inputTargets[Math.min(index + 1, this.inputTargets.length - 1)];
          this.selectInput(nextInput);
        }
      });

      input.addEventListener('input', (event) => {
        const currentInput = event.target;
        const chars = currentInput.value.toString().replaceAll(/\s/ig, '');
        const valueForInput = chars.slice(0, 1).toUpperCase();
        const rest = chars.slice(1);

        if (valueForInput.length > 0) {
          currentInput.value = valueForInput;

          if (this.codeFullyEntered() && rest.length === 0) {
            if (this.codeValue.length < 6 || this.codeWasChanged()) {
              this.form.requestSubmit();
            }
          } else {
            const isLastInput = index === this.inputTargets.length - 1;
            if (!isLastInput) {
              const nextInput = this.inputTargets[index + 1];
              this.selectInput(nextInput);

              if (rest.length > 0) {
                this.setInputValue(nextInput, rest);
              }
            }
          }
        }
      });
    });

    this.form.addEventListener('submit', (event) => {
      if (!this.codeFullyEntered) {
        event.preventDefault();
      }
    });
  }

  getCurrentCode () {
    return this.inputTargets.map(input => input.value).join('');
  }

  codeFullyEntered () {
    return this.getCurrentCode().length === 6;
  }

  codeWasChanged() {
    return this.codeValue !== this.getCurrentCode();
  }

  selectInput(input) {
    input.focus()
    input.select()
    input.setSelectionRange(0, 1, "forward");
  }

  setInputValue(input, value) {
    input.value = value;
    input.dispatchEvent(new Event('input'));
  }
}
