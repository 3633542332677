import MediaPlayerController from "@/controllers/media_player_controller";

export default class extends MediaPlayerController {

  static targets = [ "controls", "curtain", "closeButton" ]
  static classes = [ "curtainOpacity" ]
  static values = {
    landscapeSource: String,
    portraitSource: String,
  }

  // callbacks

  mediaTargetConnected(element) {
    super.mediaTargetConnected(element)
    this.curtainTarget.classList.add(this.curtainOpacityClass)
    this.media.src = window.innerWidth > window.innerHeight ? this.landscapeSourceValue : this.portraitSourceValue
    this.media.load()
  }

  onPlay() {
    super.onPlay()
    this.showControls()
    this.hideControlsLater()
  }
  
  hideControlsLater() {
    this.timelineTimeout && window.clearTimeout(this.timelineTimeout)
    this.timelineTimeout = setTimeout(() => this.hideControls(), 5000)
  }

  onPause() {
    super.onPause()
    this.timelineTimeout && window.clearTimeout(this.timelineTimeout)
    this.showControls()
  }

  // actions

  hideControls() {
    this.controlsTarget.classList.add("opacity-0")
    this.closeButtonTarget.classList.add("opacity-0")
    this.curtainTarget.classList.remove(this.curtainOpacityClass)
    this.curtainTarget.classList.add("opacity-0")
  }

  showControls() {
    this.controlsTarget.classList.remove("opacity-0")
    this.closeButtonTarget.classList.remove("opacity-0")
    this.curtainTarget.classList.remove("opacity-0")
    this.curtainTarget.classList.add(this.curtainOpacityClass)
  }

}
